import { BaseModel } from "./base.model";

export class TicketTemplate extends BaseModel {
  constructor(
    public name: string = "",
    public html: string = "",
    public filename: string = ""
  ) {
    super();
  }
}
