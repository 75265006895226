import { TicketTemplate } from "@/models/ticket-template.model";
import TicketTemplatesService from "@/services/TicketTemplatesService";

const TicketTemplates = {
  namespaced: true,
  state: {
    ticketTemplate: new TicketTemplate(),
    ticketTemplates: [],
  },
  getters: {
    all: (state: { ticketTemplates: any }) => state.ticketTemplates,
    current: (state: any) => state.ticketTemplate,
  },
  mutations: {
    SET_CURRENT(state: { ticketTemplate: any }, ticketTemplate: any) {
      state.ticketTemplate = ticketTemplate;
    },
    SET_ALL(state: { ticketTemplates: any }, ticketTemplates: any) {
      state.ticketTemplates = ticketTemplates;
    },
    SET_FILTERED(
      state: { filteredTicketTemplates: any },
      ticketTemplates: any
    ) {
      state.filteredTicketTemplates = ticketTemplates;
    },
    EMPTY_CURRENT(state: { ticketTemplate: any }, styling: any) {
      state.ticketTemplate = {
        subject: "",
        greeting: "",
        before_is_html: false,
        before_text: "",
        action_url: "",
        action_text: "",
        after_is_html: false,
        after_text: "",
        ending: "",
      };
    },
  },
  actions: {
    async fetchById({ commit }: any, id: string) {
      const ticketTemplate = await TicketTemplatesService.fetchOne(id);
      commit("SET_CURRENT", ticketTemplate);
    },
    async fetchAll({ commit }: any, filter: any) {
      const ticketTemplates = await TicketTemplatesService.fetchAll();
      commit("SET_ALL", ticketTemplates);
    },
    async create({ commit }: any, payload: any) {
      const result = await TicketTemplatesService.create(payload);
    },
    async updateById({ commit }: any, payload: any) {
      const result = await TicketTemplatesService.update(payload.id, payload);
    },
    async deleteById({ commit }: any, id: string) {
      await TicketTemplatesService.delete(id);
    },
    async generateFromTicketTemplate({ commit }: any, payload: any) {
      const result = await TicketTemplatesService.generateFromTicketTemplate(
        payload
      );
    },
    async generateForGroup({ commit }: any, payload: any) {
      const result = await TicketTemplatesService.generateForGroup(payload);
    },
  },
} as any;

export default TicketTemplates;
