import axios from "@/plugins/axios.plugin";
import BaseService from "./base-service";

class TicketTemplatesService extends BaseService<any> {
  get endpoint() {
    return "/ticket-templates";
  }

  async generateFromTicketTemplate(payload: any): Promise<any> {
    const response = await axios.post<any>(
      `${this.endpoint}/generate`,
      payload
    );
    return response.data;
  }

  async generateForGroup(payload: any): Promise<any> {
    const response = await axios.post<any>(
      `${this.endpoint}/generate-for-group`,
      payload
    );
    return response.data;
  }
}

export default new TicketTemplatesService();
